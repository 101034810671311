import React, { useEffect, useState } from 'react';
import { ChatAlt2Icon } from '@heroicons/react/outline';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Loading from '../Loading';
import RestClient from '../../RestAPI/RestClient';
import AppUrl from '../../RestAPI/AppUrl';
import DetailsBody from './DetailsBody';
import PaginationComponent from '../form-controls/PaginationComponent';

const TareasIcon = ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 576 512">
        <path d="M184.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L39 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L39 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM256 96c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H288c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H288c-17.7 0-32-14.3-32-32zM192 416c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32zM80 464c-26.5 0-48-21.5-48-48s21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48z" />
    </svg>
);

const HistorialIcon = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C201.7 512 151.2 495 109.7 466.1C95.2 455.1 91.64 436 101.8 421.5C111.9 407 131.8 403.5 146.3 413.6C177.4 435.3 215.2 448 256 448C362 448 448 362 448 256C448 149.1 362 64 256 64C202.1 64 155 85.46 120.2 120.2L151 151C166.1 166.1 155.4 192 134.1 192H24C10.75 192 0 181.3 0 168V57.94C0 36.56 25.85 25.85 40.97 40.97L74.98 74.98C121.3 28.69 185.3 0 255.1 0L256 0zM256 128C269.3 128 280 138.7 280 152V246.1L344.1 311C354.3 320.4 354.3 335.6 344.1 344.1C335.6 354.3 320.4 354.3 311 344.1L239 272.1C234.5 268.5 232 262.4 232 256V152C232 138.7 242.7 128 256 128V128z" />
    </svg>
);

const DetailsNavButton = ({ onClick, icon, label, count, active = false, disabled = false }) => (
    <>
        <ReactTooltip className='tooltip-default' anchorId={'btnNav' + label} content={disabled && 'Solo los usuarios que estén dentro de colaboradores tienen este permiso'} place="top" />
        <button id={'btnNav' + label} type='button' onClick={() => !disabled && onClick()}
            className={`group ${disabled && 'cursor-not-allowed'} flex items-center h-10 px-2 py-2 -mb-px text-center ${active ? 'text-accent-2 border-accent-2 fill-accent-2' : 'fill-gray-400 text-gray-400 border-transparent'} bg-transparent border-b-2 sm:px-4 -px-1 whitespace-nowrap focus:outline-none ${!disabled && 'hover:fill-accent-2 hover:text-accent-2 hover:border-accent-2'}`}
        >
            {icon}
            <span className="mx-1 text-sm sm:text-xl"> {label} </span>
            <span className={`rounded-full ${active ? 'bg-accent-2' : 'bg-gray-400'} ${!disabled && 'group-hover:bg-accent-2'} flex justify-center items-center px-1.5 mx-1.5`}>
                <span className='text-white font-semibold text-sm'>{count}</span>
            </span>
        </button>
    </>
)

export const DetailsFooter = ({ page, count, hidden = false }) => (
    !hidden &&
    <div className='flex justify-end text-sm text-slate-500 gap-1 px-6'>
        Mostrando
        <span className='font-medium text-slate-600'>{(page * 5) - 4}</span>
        a
        <span className='font-medium text-slate-600'>{page * 5}</span>
        de
        <span className='font-medium text-slate-600'>{count}</span>
        resultados
    </div>
)

const DetailsComponent = ({ peticion_id, permissions, disabled = false }) => {
    const [historiales, setHistoriales] = useState([]);
    const [historialCount, setHistorialCount] = useState('...');
    const [respuestas, setRespuestas] = useState([]);
    const [respuestaCount, setRespuestaCount] = useState('...');
    const [tareas, setTareas] = useState([]);
    const [tareaCount, setTareaCount] = useState('...');

    const [activeTab, setActiveTab] = useState('');
    const [tabLoaded, setTabLoaded] = useState(true);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [pagination_footer, setShowPaginationFooter] = useState(false);

    // Get items count
    useEffect(() => {
        // Get Answers Count
        RestClient.GetRequest(AppUrl.RespuestasCountByPeticionId + peticion_id).then((result) => {
            setRespuestaCount(result);
        });
        // Get Tasks Count
        RestClient.GetRequest(AppUrl.TareasCountByPeticionId + peticion_id).then((result) => {
            setTareaCount(result);
        });
        // Get History Count
        RestClient.GetRequest(AppUrl.HistorialesCountByPeticionId + peticion_id).then((result) => {
            setHistorialCount(result);
        });
    }, [peticion_id]);

    // Validate if we need to render Pagination and Footer
    useEffect(() => {
        if (activeTab === 'respuestas' && respuestas.length > 0) {
            setShowPaginationFooter(true);
        } else if (activeTab === 'historial') {
            setShowPaginationFooter(true);
        } else {
            setShowPaginationFooter(false);
        }
    }, [activeTab, respuestas])

    // Load tab only if we have new historial or respuestas
    useEffect(() => {
        setTabLoaded(true);
    }, [respuestas, historiales])

    function handleRespuestasTab(_page = 1) {
        if (page !== _page || activeTab !== 'respuestas') {
            setActiveTab('respuestas');
            setTabLoaded(false);
            setPage(_page);
            RestClient.GetRequest(AppUrl.RespuestasByPeticionId + peticion_id + '/' + _page).then((result) => {
                setRespuestas(result);
            });
            RestClient.GetRequest(AppUrl.RespuestasCountByPeticionId + peticion_id).then((result) => {
                setCount(result);
            });
        }
    }

    function handleTareasTab() {
        if (activeTab !== 'tareas') {
            setActiveTab('tareas');
            setTabLoaded(false);
            RestClient.GetRequest(AppUrl.TareasByPeticionId + peticion_id).then((result) => {
                setTareas(result);
                setTabLoaded(true);
            });
        }
    }

    function handleHistorialTab(_page = 1) {
        if (page !== _page || activeTab !== 'historial') {
            setActiveTab('historial');
            setTabLoaded(false);
            setPage(_page);
            RestClient.GetRequest(AppUrl.HistorialesByPeticionId + peticion_id + '/' + _page).then((result) => {
                setHistoriales(result);
            });
            RestClient.GetRequest(AppUrl.HistorialesCountByPeticionId + peticion_id).then((result) => {
                setCount(result);
            });
        }
    }

    return (
        <>
            {/* Details Nav */}
            <div className="bg-white md:inline-flex space-y-4 md:space-y-0 w-full p-2 pb-0 text-gray-500">
                <div className="flex w-full justify-around items-center gap-8">
                    <DetailsNavButton onClick={handleRespuestasTab} icon={<ChatAlt2Icon className='w-6 h-6 mx-1' />} label='Respuestas' count={respuestaCount} active={activeTab === 'respuestas'} disabled={disabled} />
                    <DetailsNavButton onClick={handleTareasTab} icon={<TareasIcon className='w-5 h-5 mx-1' />} label='Tareas' count={tareaCount} active={activeTab === 'tareas'} disabled={disabled} />
                    <DetailsNavButton onClick={handleHistorialTab} icon={<HistorialIcon className='w-5 h-5 mx-1' />} label='Historial' count={historialCount} active={activeTab === 'historial'} disabled={disabled} />
                </div>
            </div>
            <hr />
            {/* Details Body */}
            {!tabLoaded ?
                <div className='flex w-full justify-center bg-white'>
                    <Loading />
                </div> :
                activeTab !== '' &&
                <div className={`${activeTab !== 'tareas' && 'p-4 mb-4'} md:flex-col space-y-4 md:space-y-0 w-full text-gray-500 items-start`}>
                    <PaginationComponent
                        page={page}
                        count={count}
                        handleFunction={activeTab === 'respuestas' ? handleRespuestasTab : handleHistorialTab}
                        hidden={!pagination_footer}
                    />
                    <DetailsBody
                        _data={activeTab === 'respuestas' ? respuestas : activeTab === 'historial' ? historiales : tareas}
                        activeTab={activeTab}
                        renderPlaceholder={activeTab === 'respuestas' && respuestas.length === 0}
                        canEditUser={permissions.find(ua => ua.permiso_name === 'update' && ua.modulo_name === 'usuarios') !== undefined}
                    >
                        <DetailsFooter page={page} count={count} hidden={!pagination_footer} />
                    </DetailsBody>
                </div>}
        </>
    )
}

export default DetailsComponent